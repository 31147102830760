<template>
  <WaitWrapper :loading="pageLoading">
    <div>
      <section class="mb-1">
        <div class="mb-5">
          <h2 class="main-title">Notifications</h2>
        </div>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-text-field
              label="Search..."
              solo
              dense
              hide-details
              clearable
              append-icon="mdi-magnify"
              :disabled="disableSearch"
              class="search-filter-wrapper mr-3 no-border"
              v-model="filter"
            >
            </v-text-field>
            <v-tooltip bottom :open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="26px"
                  v-bind="attrs"
                  v-on="on"
                  class="refresh-icon"
                  @click="refreshNotifications"
                  >mdi-refresh
                </v-icon>
              </template>
              <span>Refresh notifications</span>
            </v-tooltip>
            <div class="d-flex pl-4 pr-4 align-center">
              <v-switch
                v-model="readSwitch"
                @change="changeRead($event)"
              ></v-switch>
              <label class="pt-1">Read</label>
            </div>
            <div class="d-flex pr-4 align-center">
              <v-switch
                v-model="archiveSwitch"
                @change="changeArchive($event)"
              ></v-switch>
              <label class="pt-1">Archived</label>
            </div>
            <div class="d-flex pr-4 align-center">
              <v-switch
                v-model="commentsSwitch"
                @change="changeComments($event)"
              ></v-switch>
              <label class="pt-1">Comments</label>
            </div>
            <div class="d-flex pr-4 align-center">
              <v-switch
                v-model="issueStatusesSwitch"
                @change="changeIssueStatuses($event)"
              ></v-switch>
              <label class="pt-1">Issue statuses</label>
            </div>
            <div class="d-flex pr-4 align-center">
              <v-switch
                v-model="alertsSwitch"
                @change="changealertsSwitch($event)"
              ></v-switch>
              <label class="pt-1">System alerts</label>
            </div>
          </div>
          <div class="mb-1 column-filter-wrapper">
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
                  >mdi-cog-outline</v-icon
                >
              </template>
              <span>Column filters</span>
            </v-tooltip>
            <CustomMenuFilter
              :menuOpen="colFilterMenuOpen"
              menuActivator="#cog-icon"
              :menuItems="columnFilterMenuItems"
              :showAll="showAllCols"
              offset="y"
              filterType="column"
              table="notifications_table"
              @changeFilters="toggleColSelection"
              @clearSelectedItems="clearSelectedColItems"
              @setSearchText="setColumnFilterSearchText"
              @clearFilterSearch="clearColumnFilterSearch"
            />
          </div>
        </div>
      </section>
      <section class="data-table d-flex">
        <div id="tableId">
          <div
            class="d-flex justify-center align-center data-table__spinner"
            v-if="tableItemsLoading"
          >
            <v-progress-circular
              size="55"
              rotate="180"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

          <v-data-table
            :headers="mutableTableHeaders"
            :items="filteredResults"
            item-key="id"
            :options.sync="tableOptions"
            @update:options="tableOptionsUpdated()"
            @current-items="handleItemUpdate($event)"
            @update:items-per-page="updateItemsPerPage($event)"
            @update:page="updatePage($event)"
            @update:sort-desc="updateSortDirection($event)"
            page-text="Notifications per page"
            :server-items-length="pagination.total"
            :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }"
          >
            <template v-slot:footer.page-text="">
              {{ customPageText(pagination) }}
            </template>
            <template v-slot:top>
              <div
                class="d-flex align-center justify-space-between flex-wrap"
                v-if="filteredResults.length > 0"
              >
                <div class="d-flex mb-1">
                  <div class="checkbox-container ml-4">
                    <div class="mr-2 checkbox-all">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <input
                            v-bind="attrs"
                            v-on="on"
                            type="checkbox"
                            v-model="isCheckAll"
                            @click="checkAll()"
                          />
                        </template>
                        <span>Select all</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div
                    class="d-flex ml-5 pointer notification-menu"
                    v-if="showNotificationMenu"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                          @click="markBulkNotifications('archive')"
                          >mdi-archive-arrow-up</v-icon
                        >
                      </template>
                      <span>Archive</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                          @click="markBulkNotifications('unarchive')"
                          v-if="archiveSwitch"
                          >mdi-archive-arrow-down</v-icon
                        >
                      </template>
                      <span>Unarchive</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                          @click="markBulkNotifications('read')"
                          >mdi-text-box-check</v-icon
                        >
                      </template>
                      <span>Mark as read</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                          @click="markBulkNotifications('unread')"
                          v-if="readSwitch"
                          >mdi-text-box</v-icon
                        >
                      </template>
                      <span>Mark as unread</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:body="{ items, headers }">
              <tbody v-if="items.length > 0">
                <tr
                  v-for="item in items"
                  :key="item.id"
                  @mouseenter="showToolbarItems(item)"
                  @mouseleave="hideToolbarItems(item)"
                  class="pointer table-row"
                  :class="{
                    'read-item': item.read,
                    'unread-item': !item.read,
                    elevated: elevateNotification,
                  }"
                >
                  <td class="checkbox-cell">
                    <div class="notification-checkbox">
                      <input
                        type="checkbox"
                        :value="item"
                        v-model="selectedNotifications"
                        @change="updateCheckAll($event)"
                      />
                    </div>
                  </td>
                  <td
                    class="type-cell"
                    v-if="checkColumn('type')"
                    :class="{ 'archived-item': item.archived }"
                    @click="goToSelection(item)"
                  >
                    <div class="d-flex justify-center">
                      {{ item.type }}
                    </div>
                  </td>
                  <td
                    class="message-cell"
                    v-if="checkColumn('message')"
                    :class="{ 'archived-item': item.archived }"
                    @click="goToSelection(item)"
                  >
                    {{ item.message }}
                  </td>
                  <td
                    class="timestamp-cell"
                    v-if="checkColumn('ts')"
                    :class="{ 'archived-item': item.archived }"
                  >
                    <div class="d-flex justify-center">
                      <div v-if="notificationId === item.id">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              @click="
                                markNotificationReadOrArchived(item, 'archived')
                              "
                              >{{ setArchiveIcon(item) }}</v-icon
                            >
                          </template>
                          <span>{{ setArchiveTooltip(item) }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              @click="
                                markNotificationReadOrArchived(item, 'read')
                              "
                              >{{ setReadIcon(item) }}</v-icon
                            >
                          </template>
                          <span>{{ setReadTooltip(item) }}</span>
                        </v-tooltip>
                      </div>
                      <p class="ts-display" v-else>
                        {{ item.created_ts }}
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td :colspan="headers.length" style="text-align: center">
                    No notifications to display at this time.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </section>
    </div>
  </WaitWrapper>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { popupDelay } from "@/helpers/variables";
import WaitWrapper from "@/components/WaitWrapper";
import CustomMenuFilter from "@/components/CustomMenuFilter";

export default {
  name: "NotificationManager",
  components: {
    WaitWrapper,
    CustomMenuFilter,
  },
  data() {
    const notificationMenu = [
      {
        icon: "mdi-text-box-multiple",
        title: "Archive",
      },
      {
        icon: "mdi-text-box-check",
        title: "Mark as read",
      },
      {
        icon: "mdi-text-box",
        title: "Mark as unread",
      },
    ];

    return {
      notificationMenu,
      selectCheckbox: false,
      readSwitch: true,
      archiveSwitch: false,
      commentsSwitch: true,
      issueStatusesSwitch: true,
      alertsSwitch: true,
      showNotificationMenu: false,
      filter: "",
      tableData: [],
      selectedNotifications: [],
      notificationId: null,
      elevateNotification: false,
      isCheckAll: false,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["ts"],
        sortDesc: [true],
      },
      searchDisabled: false,
      x: 185,
      y: 130,
      currentItems: [],
      tableItemsLoading: false,
      pageLoading: true,
      popupDelay,
      colFilterMenuOpen: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      searchColumnMenuText: "",
      showAllCols: false,
    };
  },
  async created() {
    this.savePaginationOptions({
      limit: 10,
      page: 1,
    });
  },
  mounted() {
    this.updateCommentsSwitch(this.commentsSwitch);
    this.updateArchiveSwitch(this.archiveSwitch);
    this.updateReadSwitch(this.readSwitch);
    this.updateIssueStatusesSwitch(this.issueStatusesSwitch);
    this.updatealertsSwitch(this.alertsSwitch);
    this.setDataByUrl();
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.userNotifications,
      paginationConfig: (state) => state.notifications.paginationOptions,
    }),
    filteredResults() {
      if (this.filter !== "") {
        return this.tableItems.filter(
          (x) =>
            x.type.toLowerCase().includes(this.filter.toLowerCase()) ||
            x.message.toLowerCase().includes(this.filter.toLowerCase()) ||
            x.ts.toLowerCase().includes(this.filter.toLowerCase()),
        );
      }

      return this.tableItems;
    },
    tableHeaders() {
      const headers = [
        { id: 1, text: "", value: "checkbox", sortable: false, show: true },
        {
          id: 2,
          text: "Type",
          value: "type",
          align: "center",
          sortable: false,
          show: true,
        },
        {
          id: 3,
          text: "Message",
          value: "message",
          align: "center",
          sortable: false,
          show: true,
        },
        {
          id: 4,
          text: "Created",
          value: "ts",
          align: "center",
          sortable: true,
          show: true,
        },
      ];
      return headers;
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
    tableItems() {
      if (this.tableData.length > 0) {
        return this.tableData;
      } else if (this.notifications?.length > 0) {
        return this.mapTableData(this.notifications);
      } else {
        return [];
      }
    },
    disableSearch() {
      return (
        this.isCheckAll ||
        this.searchDisabled ||
        this.selectedNotifications.length > 0
      );
    },
    pagination() {
      if (this.paginationConfig?.total) {
        return this.paginationConfig;
      } else {
        return {
          total: this.tableOptions?.itemsPerPage,
        };
      }
    },
  },
  methods: {
    ...mapActions({
      getNotifications: "notifications/getUserNotifications",
      markNotificationReadArchived: "notifications/putNotificationReadArchived",
      updateReadSwitch: "notifications/updateReadSwitch",
      updateArchiveSwitch: "notifications/updateArchiveSwitch",
      updateCommentsSwitch: "notifications/updateCommentsSwitch",
      updateIssueStatusesSwitch: "notifications/updateIssueStatusesSwitch",
      updatealertsSwitch: "notifications/updatealertsSwitch",
      setUploadNotification: "notifications/saveUploadNotification",
      updateIsNotificationNew: "notifications/updateIsNotificationNew",
      savePaginationOptions: "notifications/saveNotificationPagination",
      updateIssueTurbineId: "issueDetail/updateIssueTurbineId",
    }),
    mapTableData(notifications) {
      let tableData = [];

      if (notifications.length > 0) {
        tableData = notifications.map((notification) => ({
          ...notification,
          id: notification.id,
          author: notification.actor_user_full_name,
          type: this.findType(notification.entity_type),
          content: notification.content,
          definition: notification.turbine_issue_def_name,
        }));
      }

      return tableData;
    },
    async setDataByUrl() {
      const url = new URL(window.location.href);
      if (url.searchParams.toString()) {
        // Filter display data with url params
        this.readSwitch = +url.searchParams.get("include_read") ? true : false;
        this.archiveSwitch = +url.searchParams.get("include_archived")
          ? true
          : false;
        this.commentsSwitch = +url.searchParams.get("include_comments")
          ? true
          : false;
        this.issueStatusesSwitch = +url.searchParams.get(
          "include_issue_statuses",
        )
          ? true
          : false;
        this.alertsSwitch = +url.searchParams.get("include_alerts")
          ? true
          : false;
        this.tableOptions.itemsPerPage =
          +url.searchParams.get("items_per_page");
        this.tableOptions.page = +url.searchParams.get("page");
        this.tableOptions.sortDesc = [
          +url.searchParams.get("sort_desc") ? true : false,
        ];
      } else {
        // Update the browser url params
        const params = {
          include_read: this.readSwitch ? 1 : 0,
          include_archived: this.archiveSwitch ? 1 : 0,
          include_comments: this.commentsSwitch ? 1 : 0,
          include_issue_statuses: this.issueStatusesSwitch ? 1 : 0,
          include_alerts: this.alertsSwitch ? 1 : 0,
          items_per_page: this.tableOptions.itemsPerPage,
          page: this.tableOptions.page,
          sort_by: this.tableOptions.sortBy[0],
          sort_desc: this.tableOptions.sortDesc[0] ? 1 : 0,
        };
        const newUrl = this.setUrlParams(url, params);
        // Trigger the updated url params to show in the browser search bar
        history.replaceState(null, null, newUrl);
      }

      await this.getNotifications({
        limit: this.tableOptions.itemsPerPage,
        offset: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
        read: this.readSwitch,
        archived: this.archiveSwitch,
        comments: this.commentsSwitch,
        issue_statuses: this.issueStatusesSwitch,
        alerts: this.alertsSwitch,
      });

      this.pageLoading = false;
    },
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    findType(type) {
      if (type?.includes("status")) {
        return "Issue status";
      } else if (type?.includes("document")) {
        return "Document upload";
      } else if (type?.includes("comment")) {
        return "Comment";
      } else if (type?.includes("alert")) {
        return "System alert";
      }
    },
    setArchiveIcon(notification) {
      if (notification?.archived) {
        return "mdi-archive-arrow-down";
      } else {
        return "mdi-archive-arrow-up";
      }
    },
    setArchiveTooltip(notification) {
      if (notification?.archived) {
        return "Unarchive notification";
      } else {
        return "Archive notification";
      }
    },
    setReadIcon(notification) {
      if (notification?.read) {
        return "mdi-text-box-check";
      } else {
        return "mdi-text-box";
      }
    },
    setReadTooltip(notification) {
      if (notification?.read) {
        return "Mark as unread";
      } else {
        return "Mark as read";
      }
    },
    goToIssue(notification) {
      if (this.$route.hash) {
        this.$router.push({
          hash: "",
        });
      }
      this.updateIsNotificationNew(true);
      this.updateIssueTurbineId(notification.turbine_id);

      let routerParams = {
        name: "IssueDetail",
        params: {
          orgId: notification.org_id,
          siteId: notification.site_id,
          turbineId: notification.turbine_id,
          issueId: notification.turbine_issue_id,
        },
        query: this.$route.query,
      };

      if (notification.entity_type === "turbine_issue_comment") {
        routerParams.hash = `#comment-${notification.entity_id}`;
        routerParams.query = {
          comment: notification.entity_id,
        };
        this.$router.push(routerParams);
      } else {
        this.$router.push(routerParams);
      }
    },
    goToDocuments(notification) {
      const docUpload = {
        subTab: "documents",
        fileId: notification.entity_id,
        siteId: notification.site_id,
      };
      // Use vuex store to save entity info
      this.setUploadNotification(docUpload);

      this.$router.push({
        name: "SiteDashboard",
        params: {
          orgId: notification.org_id,
          siteId: notification.site_id,
        },
      });
    },
    goToAlert(notification) {
      // TODO: This doesn't use the default query params of the data tab
      this.$router.push({
        name: "SiteDashboard",
        hash: "#data",
        params: {
          orgId: notification.org_id,
          siteId: notification.site_id,
        },
      });
    },
    goToSelection(notification) {
      if (notification) {
        this.markNotificationReadOrArchived(notification, "selection");

        switch (notification.entity_type) {
          case "farm_document":
            this.goToDocuments(notification);
            break;
          case "turbine_issue_status":
            this.goToIssue(notification);
            break;
          case "turbine_issue_comment":
            this.goToIssue(notification);
            break;
          case "alert":
            this.goToAlert(notification);
            break;
        }
      }
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.selectedNotifications = [];

      if (this.isCheckAll) {
        this.searchDisabled = true;
        this.selectedNotifications = this.currentItems;
        this.showNotificationMenu = true;
      } else {
        this.searchDisabled = false;
        this.showNotificationMenu = false;
      }
    },
    async markNotificationReadOrArchived(notification, mode) {
      this.tableItemsLoading = true;
      let notificationObj = {
        id: notification.id,
        mode: mode,
        archived: notification.archived,
        read: notification.read,
      };

      if (mode === "read") {
        if (notification.read) {
          notificationObj.read = false;
        } else {
          notificationObj.read = true;
        }
      } else if (mode === "selection") {
        notificationObj.read = true;
      } else if (mode === "archived") {
        if (notification.archived) {
          notificationObj.archived = false;
        } else {
          notificationObj.archived = true;
        }
      }

      await this.markNotificationReadArchived(notificationObj);
      this.refreshNotifications();
      this.savePaginationOptions({
        limit: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
      });
      if (mode === "archived") {
        await this.getNotifications({
          limit: 20,
          offset: 0,
          container: "dropdown",
        });
      }
      this.tableItemsLoading = false;
    },
    updateCheckAll(event) {
      if (this.selectedNotifications.length === this.currentItems.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }

      if (event.srcElement.checked || this.selectedNotifications.length > 0) {
        this.showNotificationMenu = true;
      } else {
        this.showNotificationMenu = false;
      }
    },
    async markBulkNotifications(mode) {
      this.tableItemsLoading = true;
      let notificationArray = [];

      if (this.selectedNotifications.length > 0) {
        for (const notification of this.selectedNotifications) {
          let notificationObj = {};
          notificationObj.id = notification.id;
          notificationObj.mode = mode;

          switch (mode) {
            case "archive":
              notificationObj.archived = true;
              notificationObj.read = notification.read;
              break;
            case "unarchive":
              notificationObj.archived = false;
              notificationObj.read = notification.read;
              break;
            case "read":
              notificationObj.archived = notification.archived;
              notificationObj.read = true;
              break;
            case "unread":
              notificationObj.archived = notification.archived;
              notificationObj.read = false;
              break;
          }

          notificationArray.push(notificationObj);
        }
        await this.markNotificationReadArchived(notificationArray);
        this.refreshNotifications();
        this.savePaginationOptions({
          limit: this.tableOptions.itemsPerPage,
        });
        await this.getNotifications({
          limit: 20,
          offset: 0,
          container: "dropdown",
        });
      }

      this.selectedNotifications = [];
      this.isCheckAll = false;
      this.showNotificationMenu = false;
      this.bulkLoading = false;
      this.searchDisabled = false;
      this.tableItemsLoading = false;
    },
    async refreshNotifications() {
      const limit = this.tableOptions.itemsPerPage;
      let offset = (this.tableOptions.page - 1) * limit;
      let params = {
        limit: limit,
        offset: offset,
        read: this.readSwitch,
        archived: this.archiveSwitch,
        comments: this.commentsSwitch,
        issue_statuses: this.issueStatusesSwitch,
        alerts: this.alertsSwitch,
      };
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }

      await this.getNotifications(params);
      this.clearCheckboxes();
    },
    async changeRead(event) {
      const url = new URL(window.location.href);
      this.tableItemsLoading = true;
      // Set store var for use in notifications dropdown
      this.updateReadSwitch(event);
      // Setting params for get notifications call
      const limit = this.tableOptions.itemsPerPage;
      let params = {
        limit: limit,
        offset: 0,
        read: event,
        archived: this.archiveSwitch,
        comments: this.commentsSwitch,
        issue_statuses: this.issueStatusesSwitch,
        alerts: this.alertsSwitch,
      };
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }
      // Update the url browser params
      let urlParams = {
        include_read: event ? 1 : 0,
        page: 1,
      };
      const newUrl = this.setUrlParams(url, urlParams);

      await this.getNotifications(params);
      // Trigger the updated url params to show in the browser search bar
      history.replaceState(null, null, newUrl);
      this.clearCheckboxes();
      this.tableOptions.page = 1;
      this.tableItemsLoading = false;
    },
    async changeArchive(event) {
      const url = new URL(window.location.href);
      this.tableItemsLoading = true;
      // Set store var for use in notifications dropdown
      this.updateArchiveSwitch(event);
      // Setting params for get notifications call
      const limit = this.tableOptions.itemsPerPage;
      let params = {
        limit: limit,
        offset: 0,
        read: this.readSwitch,
        archived: event,
        comments: this.commentsSwitch,
        issue_statuses: this.issueStatusesSwitch,
        alerts: this.alertsSwitch,
      };
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }
      // Update the browser url params
      let urlParams = {
        include_archived: event ? 1 : 0,
        page: 1,
      };
      const newUrl = this.setUrlParams(url, urlParams);

      await this.getNotifications(params);
      // Trigger the updated url params to show in the browser search bar
      history.replaceState(null, null, newUrl);
      this.clearCheckboxes();
      this.tableOptions.page = 1;
      this.tableItemsLoading = false;
    },
    async changeComments(event) {
      const url = new URL(window.location.href);
      this.tableItemsLoading = true;
      // Set store var for use in notifications dropdown
      this.updateCommentsSwitch(event);
      // Setting params for get notifications call
      const limit = this.tableOptions.itemsPerPage;
      let params = {
        limit: limit,
        offset: 0,
        read: this.readSwitch,
        archived: this.archiveSwitch,
        comments: event,
        issue_statuses: this.issueStatusesSwitch,
        alerts: this.alertsSwitch,
      };
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }
      // Update the browser url params
      let urlParams = {
        include_comments: event ? 1 : 0,
        page: 1,
      };
      const newUrl = this.setUrlParams(url, urlParams);

      await this.getNotifications(params);
      // Trigger the updated url params to show in the browser search bar
      history.replaceState(null, null, newUrl);
      this.clearCheckboxes();
      this.tableOptions.page = 1;
      this.tableItemsLoading = false;
    },
    async changeIssueStatuses(event) {
      const url = new URL(window.location.href);
      this.tableItemsLoading = true;
      // Set store var for use in notifications dropdown
      this.updateIssueStatusesSwitch(event);
      // Set params for get notifications call
      const limit = this.tableOptions.itemsPerPage;
      let params = {
        limit: limit,
        offset: 0,
        read: this.readSwitch,
        archived: this.archiveSwitch,
        comments: this.commentsSwitch,
        issue_statuses: event,
        alerts: this.alertsSwitch,
      };
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }
      // Update the browser url params
      let urlParams = {
        include_issue_statuses: event ? 1 : 0,
        page: 1,
      };
      const newUrl = this.setUrlParams(url, urlParams);

      await this.getNotifications(params);
      // Trigger the updated url params to show in the browser search bar
      history.replaceState(null, null, newUrl);
      this.clearCheckboxes();
      this.tableOptions.page = 1;
      this.tableItemsLoading = false;
    },
    async changealertsSwitch(event) {
      const url = new URL(window.location.href);
      this.tableItemsLoading = true;
      // Set store var for use in notifications dropdown
      this.updatealertsSwitch(event);
      // Set params for get notifications call
      const limit = this.tableOptions.itemsPerPage;
      let params = {
        limit: limit,
        offset: 0,
        read: this.readSwitch,
        archived: this.archiveSwitch,
        comments: this.commentsSwitch,
        issue_statuses: this.issueStatusesSwitch,
        alerts: event,
      };
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }
      // Update the browser url params
      let urlParams = {
        include_alerts: event ? 1 : 0,
        page: 1,
      };
      const newUrl = this.setUrlParams(url, urlParams);

      await this.getNotifications(params);
      // Trigger the updated url params to show in the browser search bar
      history.replaceState(null, null, newUrl);
      this.clearCheckboxes();
      this.tableOptions.page = 1;
      this.tableItemsLoading = false;
    },
    clearCheckboxes() {
      this.selectedNotifications = [];
      this.isCheckAll = false;
      this.showNotificationMenu = false;
    },
    showToolbarItems(notification) {
      this.elevateNotification = true;
      this.notificationId = notification.id;
    },
    hideToolbarItems() {
      this.elevateNotification = false;
      this.notificationId = null;
    },
    tableOptionsUpdated() {
      this.currentItems = [];
      this.clearCheckboxes();
    },
    handleItemUpdate(event) {
      this.currentItems = event;
      if (this.isCheckAll) {
        this.isCheckAll = false;
      }
    },
    async updateItemsPerPage(event) {
      const url = new URL(window.location.href);
      this.tableItemsLoading = true;
      let params = {
        limit: event,
        offset: 0,
        read: this.readSwitch,
        archived: this.archiveSwitch,
        comments: this.commentsSwitch,
        issue_statuses: this.issueStatusesSwitch,
        alerts: this.alertsSwitch,
      };
      this.savePaginationOptions({
        limit: event,
        page: this.tableOptions.page,
      });
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }
      // Set the browser url params
      const urlParams = {
        items_per_page: event,
        page: 1,
      };
      const newUrl = this.setUrlParams(url, urlParams);

      await this.getNotifications(params);
      // Trigger the updated url params to show in the browser search bar
      history.replaceState(null, null, newUrl);
      this.tableItemsLoading = false;
    },
    async updatePage(event) {
      const url = new URL(window.location.href);
      this.tableItemsLoading = true;
      const limit = this.tableOptions.itemsPerPage;
      const offset = (event - 1) * limit;
      let params = {
        limit: limit,
        offset: offset,
        read: this.readSwitch,
        archived: this.archiveSwitch,
        comments: this.commentsSwitch,
        issue_statuses: this.issueStatusesSwitch,
        alerts: this.alertsSwitch,
      };
      this.savePaginationOptions({
        limit: limit,
        page: event,
      });
      if (this.tableOptions.sortDesc[0] !== undefined) {
        params.sortDesc = this.tableOptions.sortDesc[0];
      }
      // Set the browser url params
      const urlParams = {
        page: event,
      };
      const newUrl = this.setUrlParams(url, urlParams);

      await this.getNotifications(params);
      // Trigger the updated url params to show in the browser search bar
      history.replaceState(null, null, newUrl);
      this.tableItemsLoading = false;
    },
    async updateSortDirection(event) {
      const url = new URL(window.location.href);
      // Call to get notifications for a new set of sorted data
      if (event !== undefined) {
        let params = {
          limit: this.tableOptions.itemsPerPage,
          offset: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
          read: this.readSwitch,
          archived: this.archiveSwitch,
          comments: this.commentsSwitch,
          issue_statuses: this.issueStatusesSwitch,
          alerts: this.alertsSwitch,
        };

        this.tableItemsLoading = true;
        params.sortDesc = event;

        // Set the browser url params
        const urlParams = {
          sort_desc: event ? 1 : 0,
          page: 1,
        };
        const newUrl = this.setUrlParams(url, urlParams);

        await this.getNotifications(params);
        // Trigger the updated url params to show in the browser search bar
        history.replaceState(null, null, newUrl);
        this.tableItemsLoading = false;
      } else {
        return;
      }
    },
    customPageText(pagination) {
      const from = pagination.start_index;
      const to = pagination.end_index;
      let total = pagination.total;

      if (total >= 1000) {
        return `${from}-${to} of 999+`;
      }
      return `${from}-${to} of ${total}`;
    },
    setUrlParams(url, params) {
      if (Object.keys(params).length > 0) {
        // Set each search param's name to the param property and value to the param property's value
        for (const key in params) {
          url.searchParams.set(key, params[key]);
        }
        return url;
      }
    },
  },
  watch: {
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          for (const header of data) {
            this.selectableTableHeaders.push(header);
            this.mutableTableHeaders.push(header);
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.notification-label {
  margin-top: 9px;
}

.switch-container {
  width: 150px;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin: 0;
}

.checkbox-all {
  padding-top: 7px;
}

.checkbox-container input {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.notification-menu {
  width: 130px;
}

.checkbox-cell {
  width: 20px;
}

.notification-checkbox {
  display: flex;
  align-items: center;
}

.notification-checkbox input {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.type-cell {
  max-width: 10px;
}

.message-cell {
  max-width: 250px;
}

.timestamp-cell {
  max-width: 10px;
}

.title-hover .v-list-item__title:hover {
  color: #0d47a1 !important;
}

::v-deep {
  .page-options .v-select {
    width: 45px;
    font:
      14px Inter,
      sans-serif;
  }
  .page-options .v-select__selection {
    margin: 0;
    margin-bottom: 1px;
  }
  .page-options .v-input {
    padding: 0;
  }
  .page-options .v-input__slot::before,
  .page-options .v-input__slot::after {
    border-style: none !important;
  }
  .page-options .v-input__append-inner {
    padding: 0;

    .v-input__icon .v-icon::before,
    .v-input__icon .v-icon::after {
      padding-bottom: 1.2rem;
      padding-right: 0.05rem;
    }
  }
}

.pages {
  padding-top: 9px;
}

.refresh-icon:hover {
  color: $primaryBlue;
}

#tableId {
  width: 100%;
}

.table-row {
  height: 30px;
}

.read-item {
  background: rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.unread-item {
  font-weight: 700;
}

.archived-item {
  color: rgba(153, 153, 153, 1);
}

.ts-display {
  margin: 0;
}

.elevated:hover {
  background-color: var(--v-hover-base);
}

.data-table {
  position: relative;

  &__spinner {
    position: absolute;
    top: 25%;
    left: 45%;
    z-index: 100;
  }
}
</style>
